import Echo from 'laravel-echo';
import Pusher from "pusher-js";

try {
    if(typeof window !== "undefined") {
        window.Pusher = Pusher;
        window.Echo = new Echo({
            broadcaster: 'reverb',
            key: import.meta.env.VITE_REVERB_APP_KEY,
            wsHost: import.meta.env.VITE_REVERB_HOST,
            wsPort: import.meta.env.VITE_REVERB_PORT,
            wssPort: import.meta.env.VITE_REVERB_PORT,
            forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
            encrypted: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
        });
    }
} catch (e) {
    console.error(e);
}
