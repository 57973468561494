<script setup>
import { Link, usePage } from '@inertiajs/vue3';
import { ref } from 'vue';

const props = defineProps({
    items: Array,
    shrink: Boolean,
    logo: Object,
});

const hover = ref({})
const categoryHeaderStyle = (group) => {
    const { defaultCategoryHeader } = usePage().props;
    const url = group?.category_header?.url || defaultCategoryHeader?.url;

    return {
        style: url
            ? { backgroundImage: `url(${url})` }
            : {
                  backgroundImage: `url(${new URL('../../assets/category-header.webp', import.meta.url).href})`,
              },
        class: url ? 'has-custom-bg' : '',
    };
};
</script>

<template>
    <div class="c-horizontal-menu d-flex align-self-stretch">
        <v-hover v-for="item in items" :key="item.id" v-model="hover[item.id]">
            <template v-slot:default="{ isHovering, props }">
                <Link
                    :href="item.url"
                    class="text-caption font-weight-bold px-2 px-lg-6 cursor-pointer position-relative text-black d-inline-flex align-center"
                    v-bind="props"
                    :class="[isHovering ? 'text-decoration-underline' : '']"
                    :onSuccess="() => {hover[item.id] = false;}"
                >
                    {{ item.name }}
                </Link>
                <teleport to="body">
                    <div
                        v-if="isHovering"
                        v-bind="props"
                        class="horizontal-menu"
                        :class="shrink ? 'shrink' : ''"
                    >
                        <v-container>
                            <div class="py-8 px-10 bg-white elevation-12 elevation-6 d-flex">
                                <div class="w-50">
                                    <p
                                        class="text-uppercase text-cis-accent-color font-weight-bold mb-4"
                                    >
                                        {{ item.name }}
                                    </p>
                                    <div class="c-grid ga-2 w-auto">
                                        <v-hover v-for="(children, i) in item.children">
                                            <template v-slot:default="{ isHovering, props }">
                                                <Link
                                                    :href="children.url"
                                                    class="hm-item cursor-pointer text-black"
                                                    v-bind="props"
                                                    :class="
                                                        isHovering
                                                            ? 'text-decoration-underline'
                                                            : ''
                                                    "
                                                    :onSuccess="() => {hover[item.id] = false;}"
                                                >
                                                    {{ children.name }}
                                                </Link>
                                            </template>
                                        </v-hover>
                                    </div>
                                </div>
                                <v-spacer></v-spacer>
                                <div class="position-relative">
                                    <img
                                        :src="logo?.src"
                                        :alt="logo?.alt"
                                        width="110px"
                                        class="durance-logo"
                                    />
                                    <div
                                        class="category-illustration"
                                        :class="categoryHeaderStyle(item).class"
                                        :style="categoryHeaderStyle(item).style"
                                    ></div>
                                </div>
                            </div>
                        </v-container>
                    </div>
                </teleport>
            </template>
        </v-hover>
        <template v-if="$page.props.menu_links?.length">
            <v-hover v-for="link in $page.props.menu_links" :key="link.id">
                <template v-slot:default="{ isHovering, props }">
                    <Link
                        v-if="link.type === 'inside' && link.target === '_self'"
                        v-bind="props"
                        :class="[
                            isHovering ? 'text-decoration-underline' : '',
                        ]"
                        class="text-body-2 px-2 px-lg-6 cursor-pointer position-relative text-black d-inline-flex align-center"
                        :href="link.url"
                    >
                        {{ link.label }}
                    </Link>
                    <a
                        v-bind="props"
                        v-else
                        :href="link.url"
                        :target="link.target"
                        :class="[
                            isHovering ? 'text-decoration-underline' : '',
                        ]"
                        class="text-body-2 px-2 px-lg-6 cursor-pointer position-relative text-black d-inline-flex align-center"
                    >
                        {{ link.label }}
                    </a>
                </template>
            </v-hover>
        </template>
    </div>
</template>

<style scoped lang="scss">
@use 'vuetify/lib/styles/settings/variables' as *;
@use 'sass:map';
@use 'sass:color';
.horizontal-menu {
    position: fixed;
    width: 100%;
    top: 138px;
    z-index: 9999;
    transition: top 0.1s ease;
    &.shrink {
        top: 38px;
    }
    @media #{map.get($display-breakpoints, 'md-and-down')}{
        top: 114px;
        &.shrink {
            top: 102px;
        }
    }
}

.c-grid {
    display: grid;
    grid-template-columns: 3fr 3fr;
}

.category-illustration {
    width: 300px;
    min-height: 200px;
    background-position: center;
    background-size: cover;
    border-radius: 16px;

    &.has-custom-bg {
        filter: brightness(1.2);
        opacity: 0.2;
    }
}

.durance-logo {
    position: absolute;
    filter: color.alpha(white);
    bottom: 20px;
    mix-blend-mode: darken;
    left: -30px;
}

.c-horizontal-menu {
    &.slide-x-transition-leave-active {
        .text-caption {
            white-space: nowrap;
        }
    }
}
</style>
